@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'Lato', sans-serif;
  margin: 0;
  background: #f7f7f8;
  background: #f7f7f8;
  line-height: 1.25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

header,
footer {
  right: 0;
  left: 0;
  z-index: 99;
  background-color: #ffffff;
  color: #323c67;
  font-weight: bold;
}

h1 {
    font-family: 'sofia-pro';
    margin: 12px 0 18px;
    font-size: 24px;
    padding: 0 8px;
}

.hm-link {
    font-family: 'sofia-pro';
    font-weight: 700;
    color: #151e41;
    width: 100%;
    font-size: 18px;
    padding: 8px;
    display: flex;
    border-bottom: 1px solid #757575;
}

.hm-sub-cat {
  margin-left: 5px;
  font-weight: 500;
}

.author {
    margin: 4px 8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap: 20px;
    font-size: 16px;
    border-bottom: 1px solid #75757575;
    padding-bottom: 5px;
    font-weight: 700;
    font-family: 'sofia-pro';
}

.author-cat {
  display: block;
  font-weight: 300;
}

/* MAIL CHIMP CSS */

#mc_embed_signup form {
    margin: 0px 0px 16px 0!important;
    padding: 0 8px;
    border-top: 1px solid #757575;
    border-bottom: 1px solid #757575;
}

#mc_embed_signup h2,
#mc_embed_signup .mc-field-group label,
#mc_embed_signup .indicates-required {
    font-family: 'sofia-pro';
}
#mc_embed_signup h2 {
    margin: 15px 0 0;
}

#mc_embed_signup .indicates-required {
  height: 0px;
}

#mc_embed_signup .button {
    font-weight: 700;
    font-family: 'sofia-pro';
    background-color: #151e41 !important;
}

#page_header {
  width: 100%;
  position: fixed;
  top: 0;
  height: 56px;
  text-align: center;
  line-height: 72px;
  font-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #101E41;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.08);
  z-index: 100;
}

.top_header_desktop {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRHTR.svg");
  background-repeat: no-repeat !important;
  background-position: 26% !important;
  background-color: #101E41 !important;
  background-size: 600px !important;
  padding: 30px 0px !important;
}


.top_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRHTR.svg");
}

.sports_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRSport.svg");
}

.fashion_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRFashion.svg");
}

.entertainment_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNREnter.svg");
}

.news_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRNews.svg");
}

.wellness_header {
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/BNRWellness.svg");
}

#ob_sf_loader > div {
  background-color:  #ee4445 !important;
  width: 18px; 
  height: 18px; 
  border-radius: 100%; 
  display: inline-block;  
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both; 
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

#ob_sf_loader .bounce1 { 
  -webkit-animation-delay: -0.32s;  
  animation-delay: -0.32s;
} 

#ob_sf_loader .bounce2 { 
  -webkit-animation-delay: -0.16s;  
  animation-delay: -0.16s;
  background-color: #2d5cc8 !important;
}  

#ob_sf_loader .bounce3 { 
  background-color: white !important;
  border: 1px solid grey;
} 

#ob_sf_loader 
        {
        margin: 25px auto 20px; 
        width: 70px; 
        text-align: center;
}

.number {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  padding: 8px;
  border: 1px solid #fff;
  color: #fff;
  justify-content: center;
  font: 12px Lato, sans-serif;
  align-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  clear: both;
  display: grid;
  background: #151e41 !important;
  /* background: #EE4444 !important; */
}

:root {
  --article-bg-color: #fff;
  --h2-color: #000;
  --footer-color: #757575;
}

/* quick reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 16px;
  background-color: white;
  padding: 3px 3px 0 3px;
}

h4 {
  margin-bottom: 5px;
}

a {
  text-decoration: none;
}

/* section elements */

main {
  /* helps to create a more balanced layout */
  background: #eee;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  grid-auto-rows: 0;
  margin: 0 auto;
  padding: 1.25rem;
  max-width: 24.375rem;
  display: grid;
}

.article-second-part {
  padding-bottom: 22px;
}

.widget-wrapper {
  min-height: 100vh;
  max-width: 100%;
  margin: auto;
  margin-top: 57px;
  padding: 16px 8px 75px 8px;
  background: #FFFFFF;
}

.article-wrapper {
  min-height: 100vh;
  max-width: 100%;
  margin: 56px auto auto;
  background: #FFFFFF;
}

.figure, p {
    margin-bottom: 20px;
    padding: 0 8px;
    line-height: 1.5;
}

.hyperlink {
    font-weight: bold;
    text-decoration: underline;
    color: #ee4445;
}

#openseaCard {
  margin-bottom: 24px;
  box-shadow: 0px 0px 0px 1px #e5e4e7;
  padding: 16px 16px 24px 16px;
  margin: 0 -8px;
  min-height: 50px;
}

.bnr-joker {
  max-width: 100%;
  padding: 10px 0 10px 0;
}

.vi_header {
  font-size: 18px;
  font-family: Lato;
  font-weight: bold
}

#vi-stories-player-container {
  border-radius: 5px
}

.vi_feed {
  margin-top: 15px
}

#rec_container {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 0;
}

article {
  background: var(--article-bg-color);
  border-radius: 0.3125rem 0.3125rem 0 0;
  padding-bottom: 0.625rem;
  display: grid;
  clear: both;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: repeat(1, minmax(min-content, 1fr));
  margin: 0px 0px 0px 0px;
  /* grid-row-gap: 15px; */
  grid-column-gap: 15px;
}

.ob-rec {
  border-radius: 5px;
}

.ob-rec:nth-of-type(odd) {
  grid-row-end: span 19;
}

.ob-rec:nth-of-type(even) {
  grid-row-end: span 15;
}

.ob-rec:nth-of-type(even) .image {
  max-height: 120px;
  overflow: hidden;
}

.ob-rec:nth-of-type(odd) .title {
  -webkit-line-clamp: 4;
}

.ob-rec:nth-of-type(odd) .image {
  line-height: 0;
}

img {
  width: 100%;
}

.ob-topic-container:nth-child(5) {
  margin-bottom: 0px !important
}


.sub-unit {
  margin: 8px 8px 8px 8px;
}

span.ob-unit.ob-rec-date:before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("https://s3.amazonaws.com/static-test.outbrain.com/gto/bnr/assets/clock.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  top: 2px;
  position: relative;
  margin-right: 4px;
}


.title {
  -webkit-line-clamp: 3;
  font-family: inherit;
  color: black;
  margin: 0px 0px 0px 0px;
  text-align: left;
  line-height: 1.25;
  font-size: 16px;
  flex: auto;
  font-weight: bold;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}

.source {
  -webkit-line-clamp: 1;
  font-family: inherit;
  color: #757575;
  margin: 4px 0px 0px 0px;
  text-align: left;
  font-size: 14px;
  line-height: 1.25;
  word-break: break-all;
}

footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding-bottom: 20px;
}

footer a {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}

footer .powered-by {
  text-decoration: none;
  color: #757575;
  font-size: 11px;
  font-family: arial;
}

.footer-text p {
  margin: 3px 0px;
}

.footer-logo-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo-inner p {
  margin: 0 10px 5px 0;
  line-height: 1.6;
}

.privacy,
.terms {
  width: 50%;
  float: left;
}

.os-title {
  font-family: sofia-pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 12px;
  color: rgba(21, 30, 65, 1);
}

@media screen and (min-device-width: 360px) and (max-device-width: 360px) and (max-device-height: 740px) {
  .ob-rec:nth-of-type(odd) {
    grid-row-end: span 18;
  }
}


@media screen and (min-device-width: 414px) and (max-device-width: 414px) and (max-device-height: 896px) {
  .ob-rec:nth-of-type(odd) {
    grid-row-end: span 20;
  }
  .source {
    margin: 8px 0px 0px 0px;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .ob-rec:nth-of-type(odd) {
    grid-row-end: span 20;
  }
}


/* Dark Mode

@media (prefers-color-scheme: dark) {
  .widget-wrapper {
    background: #000000;
  }
  .ob-rec {
    border: 1px solid #dadce063;
    background: #2a2b2e;
  }
  .title,
  .os-title,
  .vi_header {
    color: white
    
  }
  .source {
    color: #b3b3b3;
  }
  header {
    background-color: #fff;
    filter: grayscale(1) invert(1);
    border-bottom: 1px solid #dadce063;
    box-shadow: 0px 0px 12px -5px #85919d;
  }
  
  .ob-grid-layout .ob-widget-items-container .ob-dynamic-rec-container {
      background-color: #202123 !important;
      box-shadow: 0 0 0 1px #25282b !important;
  }

}

*/

.ob-topic-container {
    height: 40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 10px;
}

.arrow {
    width: auto;
    height: auto;
    flex-grow: 0;
    object-fit: contain;
    padding-right: 10px;
  }

.topic-title {
    overflow: hidden;
    font-size: 14px;
    line-height: 22px;
    height: 36px;
    flex-grow: 1;
    font-family: "Open Sans";
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4950;
    padding: 2px 8px 2px 14px;
    border-radius: 50px;
    background: #f2f1f3;
    vertical-align: middle;
    align-items: center;
    align-self: center;
    display: inline-flex;
    margin: auto;
    justify-content: space-between;
}

.search {
    padding-left: 15px;
    width: auto;
  }

.hidden {
  display: none;
  visibility: hidden;
}


ul#navid.navbar {
  list-style: none;
  padding: 0;
  margin: auto !important;
  align-content: center;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  font-size: 18px;
  font-family: Open Sans;
}

/* Style the list items (buttons) */
ul#navid.navbar li {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  color: #151E41
}

/* Hover Effect for Category */

span.navbar-button:hover {
    font-weight: bold;
}

/* Style the selected category link (button) */
li.selected {
    border-bottom: 3px solid rgb(238, 68, 69);
    padding-bottom: 2px;
    border-radius: 3px;
    font-weight: bold;
}

/* Style the navigation bar */
.navbar {
  padding: 30px 0px 0px !important;
  width: 880px;
  margin: auto;
}
